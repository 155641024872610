import React from 'react'
import {FormControl, FormGroup, IconButton} from '@mui/material';
import { Typography,Select } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Controller } from "react-hook-form";
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(() => ({
	noBorder: {
	  borderRadius: 2,
	  border: 0,
	  paddingLeft: '5px'
	},
	resize:{
		paddingTop:2,
		fontSize:12,
	    height:30
	},
}));


const DropdownInput = ({fieldName,values, fieldLabel,width,fontSize,control,children, changeValue, error, defaultValue, dir}) => {

	const classes = useStyles();

	const [open, setOpen] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

    return (
        <FormGroup style={{ marginRight: 5, marginLeft: 5, marginBottom: 0,   flexDirection: dir ? dir : 'column', alignItems: dir ? 'center': null }}>
            <Typography 
				className='formText' 
				sx={{  fontSize:'12px', paddingRight: 1 }}>
					{fieldLabel}
			</Typography>
				<Controller
					name={fieldName}
					control={control ? control : null}
					render={({ field: { onChange, onBlur, value } }) =>
					<Select 
						labelId="level-label" 
						value={value ? value : values }
						disableUnderline
						open={open}
						defaultValue={defaultValue}
						onClose={handleClose}
						onOpen={handleOpen}
						variant="standard" size='small' className='formText dropdown_formcontrol'
						style={{  }}
						IconComponent={() => (
							<IconButton style={{ backgroundColor:'#47C9D9', width:30, borderRadius: 4, height: 30}} onClick={() => setOpen(!open)}>
								<ArrowDropDownIcon style={{ color:'white'}} />
							</IconButton>
							
						)}
						sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
						InputProps={{
							classes:{
								notchedOutline:classes.noBorder,
								input: classes.resize,
							},
							
						}}
						onChange={changeValue ? (value) => {
							onChange(value)
							changeValue(value)
						} : onChange}
						onBlur={onBlur}
					>
						{children}
					</Select>}
				/>
			{error && <Typography color={"red"} fontSize={10}>{error?.message}</Typography>}
        </FormGroup>
    )
}

export default DropdownInput
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Grid, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Logo from '../assets/images/ugks_logo.png'
import { useNavigate } from 'react-router-dom';
import DropDownMenu from './DropDownMenu';
import Person from '@mui/icons-material/Person';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import instaLogo from '../assets/images/instagram.jpg'
import Divider from '@mui/material/Divider'
import RegisterMenu from './RegisterMenu';
import { useDispatch } from 'react-redux'
import { RESET_USER } from '../Redux/constants/userConstants';
import { BOOKING_DATA, REMOVE_ORDER_ITEM, RESET_BOOKING } from '../Redux/constants/bookingConstants';

const HomeTopBar = ({ activeMenu }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const Home = () => {
    setAnchorElNav(null);
    navigate('/home')
  };
  const AboutUs = () => {
    setAnchorElNav(null);
    navigate('/aboutus')
  };
  const Services = () => {
    setAnchorElNav(null);
    navigate('/services')
  };
  const ContactUs = () => {
    setAnchorElNav(null);
    navigate('/contactus')
  };
  const handleNavMenu1 = () => {
    setAnchorElNav(null);
    navigate('/dashboard')
  };
  const handleNavMenu2 = () => {
    setAnchorElNav(null);
    navigate('/wallet')
  };
  const handleNavMenu3 = () => {
    setAnchorElNav(null);
    navigate('/topup')
  };
  const handleNavMenu4 = () => {
    setAnchorElNav(null);
    navigate('/profile')
  };
  const handleNavMenu5 = () => {
    setAnchorElNav(null);
    navigate('/markup')
  };
  const handleNavMenu6 = () => {
    setAnchorElNav(null);
    navigate('/user')
  };
  const handleNavMenu7 = () => {
    setAnchorElNav(null);
    navigate('/role')
  };
  const handleNavMenu8 = async () => {
    setAnchorElNav(null);
    dispatch({
      type: RESET_USER
    })
    await localStorage.removeItem("user")
    await localStorage.removeItem("usercurrency")
    navigate('/home')
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const CustomerRegister = () => {
    setAnchorElNav(null);
    navigate('/customerregistration')
  };

  const CompanyRegister = () => {
    setAnchorElNav(null);
    navigate('/b2bregistration')
  };


  const resetdata = () => {
    dispatch({
      type: RESET_BOOKING,
    })


  }

  return (
    <div>
      <Grid>
        <div className='contact-list gba-hide'>
          <a href='mailto:support@gmail.com' className='e-mail'>
            <svg width="17" height="17" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 0H4C1.8 0 0 1.8 0 4V14C0 16.2 1.8 18 4 18H20C22.2 18 24 16.2 24 14V4C24 1.8 22.2 0 20 0ZM21.6 5.8L13.7 11.1C13.2 11.4 12.6 11.6 12 11.6C11.4 11.6 10.8 11.4 10.3 11.1L2.4 5.8C2 5.5 1.9 4.9 2.2 4.4C2.5 4 3.1 3.9 3.6 4.2L11.5 9.5C11.8 9.7 12.3 9.7 12.6 9.5L20.5 4.2C21 3.9 21.6 4 21.9 4.5C22.1 4.9 22 5.5 21.6 5.8Z" fill="white"/>
            </svg>
            support@jmlimousine.com
          </a>
          <a href='#' className='phone'>
            <svg width="20" height="16" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.25 15.59C10.13 21.25 14.76 25.88 20.43 28.76L24.83 24.35C25.38 23.8 26.17 23.64 26.86 23.86C29.1 24.6 31.51 25 34 25C35.11 25 36 25.89 36 27V34C36 35.11 35.11 36 34 36C15.22 36 0 20.78 0 2C0 0.89 0.9 0 2 0H9C10.11 0 11 0.89 11 2C11 4.49 11.4 6.9 12.14 9.14C12.36 9.83 12.2 10.62 11.65 11.17L7.25 15.59Z" fill="white"/>
            </svg>
            (+65)123-453-786
          </a>
          <div className='social-m'>
            <a href="#">
              <svg width="9" height="16" viewBox="0 0 15 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 10V7.02344C10 5.67969 10.2969 5 12.3828 5H15V0H10.6328C5.28125 0 3.51562 2.45313 3.51562 6.66406V10H0V15H3.51562V30H10V15H14.4062L15 10H10Z" fill="white"/>
              </svg>
            </a>
            <a href="#">
              <svg width="17" height="14" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.60409 17C14.5286 17 18.863 10.459 18.863 4.78671C18.863 4.60095 18.863 4.41601 18.8505 4.23188C19.6937 3.62421 20.4216 2.87188 21 2.01C20.2137 2.35713 19.3795 2.58474 18.5254 2.68534C19.4246 2.14906 20.098 1.30491 20.4195 0.31113C19.5738 0.811086 18.6485 1.16349 17.6837 1.35305C16.869 0.489986 15.7313 0 14.5422 0C12.1763 0 10.2296 1.93951 10.2296 4.29648C10.2296 4.62347 10.2671 4.94943 10.3412 5.26791C6.87877 5.09502 3.6483 3.46337 1.46159 0.783136C0.324475 2.73336 0.912844 5.26072 2.7955 6.51317C2.10993 6.49294 1.43907 6.30865 0.84 5.9759V6.03029C0.840574 8.06583 2.29388 9.83507 4.29659 10.2381C3.66233 10.4104 2.99668 10.4356 2.35118 10.3117C2.9142 12.056 4.53776 13.2583 6.37645 13.2927C4.85108 14.487 2.96572 15.1357 1.02564 15.1338C0.682828 15.1331 0.340348 15.1125 0 15.0718C1.97014 16.3314 4.26316 16.9998 6.60409 16.9966" fill="white"/>
              </svg>
            </a>
            <a href="#">
              <svg width="17" height="17" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.49987 3.59888C5.38294 3.59888 3.62891 5.32267 3.62891 7.46984C3.62891 9.61702 5.3527 11.3408 7.49987 11.3408C9.64705 11.3408 11.3708 9.58678 11.3708 7.46984C11.3708 5.35291 9.61681 3.59888 7.49987 3.59888ZM7.49987 9.94968C6.13899 9.94968 5.02004 8.83073 5.02004 7.46984C5.02004 6.10896 6.13899 4.99001 7.49987 4.99001C8.86076 4.99001 9.97971 6.10896 9.97971 7.46984C9.97971 8.83073 8.86076 9.94968 7.49987 9.94968Z" fill="white"/>
              <path d="M11.522 4.38514C12.0064 4.38514 12.3991 3.99248 12.3991 3.50812C12.3991 3.02376 12.0064 2.6311 11.522 2.6311C11.0377 2.6311 10.645 3.02376 10.645 3.50812C10.645 3.99248 11.0377 4.38514 11.522 4.38514Z" fill="white"/>
              <path d="M13.7903 1.23992C13.004 0.423387 11.8851 0 10.6149 0H4.38508C1.75403 0 0 1.75403 0 4.38508V10.5847C0 11.8851 0.423387 13.004 1.27016 13.8206C2.08669 14.6069 3.1754 15 4.41532 15H10.5847C11.8851 15 12.9738 14.5766 13.7601 13.8206C14.5766 13.0343 15 11.9153 15 10.6149V4.38508C15 3.11492 14.5766 2.02621 13.7903 1.23992ZM13.6694 10.6149C13.6694 11.5524 13.3367 12.3085 12.7923 12.8226C12.248 13.3367 11.4919 13.6089 10.5847 13.6089H4.41532C3.50806 13.6089 2.75202 13.3367 2.20766 12.8226C1.66331 12.2782 1.39113 11.5222 1.39113 10.5847V4.38508C1.39113 3.47782 1.66331 2.72177 2.20766 2.17742C2.72177 1.66331 3.50806 1.39113 4.41532 1.39113H10.6452C11.5524 1.39113 12.3085 1.66331 12.8528 2.20766C13.3669 2.75202 13.6694 3.50806 13.6694 4.38508V10.6149Z" fill="white"/>
              </svg>
            </a>
          </div>
        </div>
      </Grid>
    <AppBar position="static" color="default" className="header-navbar">
      <Container maxWidth="100%" height="97" width="100%" style={{ paddingLeft: 10, paddingRight: 10 }}>
        <Toolbar disableGutters >
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <img className='m-logo' src={Logo}  onClick={resetdata} />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} style={{ justifyContent: 'start' }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon className='menuicon' />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
             <span onClick={Home} >{activeMenu == '1' ? <Typography className='active'>Home</Typography> : <Typography className='inactive'>Home</Typography>}</span>
            <span onClick={AboutUs} >{activeMenu == '2' ? <Typography className='active'>About Us</Typography> : <Typography className='inactive'>About Us</Typography>}</span>
            <span onClick={Services} >{activeMenu == '3' ? <Typography className='active'>Services</Typography> : <Typography className='inactive'>Services</Typography>}</span>
            <span onClick={ContactUs}>{activeMenu == '4' ? <Typography className='active'>Contact Us</Typography> : <Typography className='inactive'>Contact Us</Typography>}</span>
            <span onClick={CustomerRegister}>{activeMenu == '5' ? <Typography className='active'>Customer Registration</Typography> : <Typography className='inactive'>Customer Registration</Typography>}</span>
            <span onClick={CompanyRegister}>{activeMenu == '6' ? <Typography className='active'>Company Registration</Typography> : <Typography className='inactive'>Company Registration</Typography>}</span>
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
            }}
          >
            <img src={Logo} style={{ width: 93, height: 70 }} onClick={resetdata} />
          </Typography>
          <Box className="navbar-menu" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <span onClick={Home} >{activeMenu == '1' ? <Typography className='active'>Home</Typography> : <Typography className='inactive'>Home</Typography>}</span>
            <span onClick={AboutUs} >{activeMenu == '2' ? <Typography className='active'>About Us</Typography> : <Typography className='inactive'>About Us</Typography>}</span>
            <span onClick={Services} >{activeMenu == '3' ? <Typography className='active'>Services</Typography> : <Typography className='inactive'>Services</Typography>}</span>
            <span onClick={ContactUs}>{activeMenu == '4' ? <Typography className='active'>Contact Us</Typography> : <Typography className='inactive'>Contact Us</Typography>}</span>
            <span onClick={CustomerRegister}>{activeMenu == '5' ? <Typography className='active'>Customer Registration</Typography> : <Typography className='inactive'>Customer Registration</Typography>}</span>
            <span onClick={CompanyRegister}>{activeMenu == '6' ? <Typography className='active'>Company Registration</Typography> : <Typography className='inactive'>Company Registration</Typography>}</span>
          </Box>
          <box className="user-navbarmenu" >

            <span className='loginmenu' style={{}} onClick={() => navigate("/signin")}>
              <Person sx={{ display: { xs: 'flex', md: 'flex' }, mr: 0 }} style={{}} /> Login
            </span>

            {/* <span className='registermenu' style={{}}>
                    <RegisterMenu menuname={"Register"}/>
                </span> */}

            {/* <FacebookRoundedIcon sx={{ display: { xs: 'flex', md: 'flex' }, mr: 0 }} style={{}}/>
                <Divider orientation="vertical" color='white' variant="middle" flexItem/>
                <img src={instaLogo} style={{ width: 25, height: 25,marginRight:50 }} />   */}
          </box>
        </Toolbar>
      </Container>
    </AppBar>
    </div>
  );
};
export default HomeTopBar;

import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CarImg from '../../assets/images/IconsNew/sedan-car-front.png'
import AgegroupImg from '../../assets/images/IconsNew/age-group.png'
import BagImg from '../../assets/images/IconsNew/luggage.png'
import Vehicle1Img from '../../assets/images/Vehi1.jpg'
import '../Summary/style.scss'
import { BOOKING_INPUT, BOOKING_TYPE, SELECTED_VEHICLE, SET_SELECTED_BOOKING } from '../../Redux/constants/bookingConstants'
import { useNavigate } from 'react-router-dom'




const Vehicle = ({ item }) => {


    const { bookingData } = useSelector(state => state.booking)
    const {user} = useSelector(state => state.auth)
    const navigate = useNavigate()
    const dispatch = useDispatch()



    const selectCurrentVehicle = async () => {

        dispatch({
            type: BOOKING_INPUT,
            payload: {
                prop: 'priceplan',
                value: item?.priceplan
            }
        })



        dispatch({
            type: BOOKING_TYPE,
            payload: 'PAL'
        })




        dispatch({
            type: SELECTED_VEHICLE,
            payload: item
        })

        dispatch({
            type: SET_SELECTED_BOOKING,
            payload: {
                booking: bookingData,
                selectedVehicle: item,
                id: 'current'
            }
        })

        navigate("/passengerdetails")
    }



    return (
        <div className="vehicleBox">

            <div className="vehicleBox_img">
                {item?.vehicleImage ? <img src={item?.vehicleImage} style={{}} resizeMode={'contain'} /> : <img src={Vehicle1Img} style={{}} resizeMode={'contain'} />}
            </div>
            <div className="vehicleBox_details">
                <Typography className='nameText vec-res-h'>{item?.vehicleType}</Typography>
                <Box className="description_holder">
                    <Box className="descriptionList">
                        {
                            item?.description ? <div className="descriptionItem" >
                                <img className="descriptionItemImg" src={CarImg} />
                                <Typography className='descriptionText' >{item?.description}</Typography>
                            </div> : <div className="descriptionItem" >
                                <Typography className='descriptionText' >{item?.description}</Typography>
                            </div>
                        }

                        <div className="descriptionItem" >
                            <img className="descriptionItemImg" src={AgegroupImg} />
                            <Typography className='descriptionText' >Max: {item?.passengers}</Typography>
                        </div>
                        <div className="descriptionItem" >
                            <img className="descriptionItemImg" src={BagImg} />
                            <Typography className='descriptionText' >Max: {item?.checkin}</Typography>
                        </div>
                    </Box>
                    <Box className="vehicle_price_holder">
                        <Typography className='pricetext' flex={0.8}>{item?.currencySymbol}{Math.ceil(item?.finaltotal)}</Typography>
                        <div className="btn-holder" >
                            <Button

                                variant="filled"
                                style={{
                                    borderRadius: 1,
                                    backgroundColor: '#333333',
                                    height: 30,
                                    borderRadius: 4,
                                    padding: 5
                                }}
                                onClick={selectCurrentVehicle}
                                className='btn-primary'
                            >
                                Select
                            </Button>
                        </div>

                    </Box>
                </Box>

            </div>

        </div >
    )
}

export default Vehicle
import PrivateRoute from "../PrivateRoute"
import B2BRegistration from "../screens/B2BRegistration"
import Booking from "../screens/Booking"
import BookLogin from "../screens/BookLogin"
import CustomerRegistration from "../screens/CustomerRegistration"

import Dashboard from "../screens/Dashboard"
import Done from "../screens/Done"
import DriverRegistration from "../screens/DriverRegistration"
import DriverTrip from "../screens/DriverTrip"
import EditTrip from "../screens/EditTrip"
import ErrorPage from "../screens/ErrorPage"
import ForgotPassword from "../screens/ForgotPassword"
import Home from "../screens/Home"
import AboutUs from "../screens/AboutUs"
import Services from "../screens/Services"
import ContactUs from "../screens/ContactUs"
import TermsConditions from "../screens/TermsConditions"
import PrivacyPolicy from "../screens/PrivacyPolicy"
import BookingForm from "../screens/Home/BookingForm"
import Login from "../screens/Login"
import MarkUp from "../screens/MarkUp"
import PassengerDetails from "../screens/PassengerDetails"
import Payment from "../screens/Payment"
import Profile from "../screens/Profile"
import ResetPassword from "../screens/ResetPassword"
import Role from "../screens/Role"
import AddRole from "../screens/Role/AddRole"
import Summary from "../screens/Summary"
import TopUp from "../screens/TopUp"
import User from "../screens/User"
import Wallet from '../screens/Wallet'


export const guest = [
    {
        path: '/',
        element: <Home />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/aboutus',
        element: <AboutUs />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/services',
        element: <Services />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/termsconditions',
        element: <TermsConditions />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/privacypolicy',
        element: <PrivacyPolicy />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/booking',
        element: <BookingForm />,
        exact: true
    },
    {
        path: '/signin',
        element: <Login />,
        exact: true
    },
    {
        path: '/forgotpassword',
        element: <ForgotPassword />,
        exact: true
    },
    {
        path: '/resetpassword/:token',
        element: <ResetPassword />,
        exact: true
    },
    {
        path: '/summary',
        element: <Summary head="ADD BOOKING" />,
        exact: true
    },
    {
        path: '/passengerdetails',
        element: <PassengerDetails head="ADD BOOKING" />,
        exact: true
    },
    {
        path: '/newbooking',
        element: <Booking head="ADD BOOKING" />,
        exact: true
    },
    
    {
        path: '/loginregister',
        element: <BookLogin head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/payment',
        element: <Payment head="ADD BOOKING" />,
        exact: true
    },
    {
        path: '/done',
        element: <Done head="ADD BOOKING" />,
        exact: true
    },
    {
        path: '/home',
        element: <Home head="ADD BOOKING" />,
        exact: true
    },
    {
        path: '/customerregistration',
        element: <CustomerRegistration  />,
        exact: true
    },
    {
        path: '/contactus',
        element: <ContactUs  />,
        exact: true
    },
    {
        path: '/b2bregistration',
        element: <B2BRegistration  />,
        exact: true
    },
    {
        path: '/driverregistration',
        element: <DriverRegistration  />,
        exact: true
    },
    {
        path: '/trip',
        element: <DriverTrip  />,
        exact: true
    },
];

export const agent =[
    {
        path: '/termsconditions',
        element: <TermsConditions />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/privacypolicy',
        element: <PrivacyPolicy />,
        exact: true,
        errorElement: <ErrorPage />,
    },

    {
        path: '/',
        element: <Home />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/booking',
        element: <BookingForm />,
        exact: true
    },
    {
        path: '/signin',
        element: <Login />,
        exact: true
    },
    {
        path: '/forgotpassword',
        element: <ForgotPassword />,
        exact: true
    },
    {
        path: '/resetpassword/:token',
        element: <ResetPassword />,
        exact: true
    },
    {
        path: '/dashboard',
        element: <PrivateRoute> <Dashboard head="MY BOOKINGS" /> </PrivateRoute>,
        exact: true
    },
    {
        path: '/topup',
        element: <PrivateRoute> <TopUp head="TOP UP"/> </PrivateRoute>,
        exact: true
    },
    {
        path: '/summary',
        element: <Summary head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/edittrip/:id',
        element: <PrivateRoute> <EditTrip head="EDIT TRIP" /> </PrivateRoute>,
        exact: true
    },
    {
        path: '/wallet',
        element: <PrivateRoute><Wallet head="MY WALLET"/></PrivateRoute>,
        exact: true
    },
    {
        path: '/addrole',
        element: <PrivateRoute><AddRole head="ROLE MANAGEMENT"/></PrivateRoute>,
        exact: true
    },
    {
        path: '/profile',
        element: <PrivateRoute><Profile head="PROFILE"/></PrivateRoute>,
        exact: true
    },
    {
        path: '/markup',
        element: <PrivateRoute><MarkUp head="MARK UP MANAGEMENT" /></PrivateRoute>,
        exact: true
    },
    {
        path: '/user',
        element: <PrivateRoute><User head="USER MANAGEMENT" /></PrivateRoute>,
        exact: true
    },
    {
        path: '/role',
        element: <PrivateRoute><Role head="ROLE MANAGEMENT" /></PrivateRoute>,
        exact: true
    },
    {
        path: '/passengerdetails',
        element: <PassengerDetails head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/newbooking',
        element: <Booking head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/payment',
        element: <Payment head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/done',
        element: <Done head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/home',
        element: <Home />,
        exact: true
    },
    {
        path: '/customerregistration',
        element: <CustomerRegistration  />,
        exact: true
    },
    {
        path: '/b2bregistration',
        element: <B2BRegistration  />,
        exact: true
    },
    {
        path: '/driverregistration',
        element: <DriverRegistration  />,
        exact: true
    },
  
];

export const customer =[

    {
        path: '/termsconditions',
        element: <TermsConditions />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/privacypolicy',
        element: <PrivacyPolicy />,
        exact: true,
        errorElement: <ErrorPage />,
    },

    {
        path: '/',
        element: <Home />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/booking',
        element: <BookingForm />,
        exact: true
    },
    {
        path: '/signin',
        element: <Login />,
        exact: true
    },
    {
        path: '/forgotpassword',
        element: <ForgotPassword />,
        exact: true
    },
    {
        path: '/resetpassword/:token',
        element: <ResetPassword />,
        exact: true
    },
    {
        path: '/dashboard',
        element: <PrivateRoute> <Dashboard head="MY BOOKINGS" /> </PrivateRoute>,
        exact: true
    },
    {
        path: '/summary',
        element: <Summary head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/edittrip/:id',
        element: <PrivateRoute> <EditTrip head="EDIT TRIP" /> </PrivateRoute>,
        exact: true
    },
    {
        path: '/profile',
        element: <PrivateRoute><Profile head="PROFILE"/></PrivateRoute>,
        exact: true
    },
    {
        path: '/passengerdetails',
        element: <PassengerDetails head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/newbooking',
        element: <Booking head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/payment',
        element: <Payment head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/done',
        element: <Done head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/home',
        element: <Home />,
        exact: true
    },
    {
        path: '/customerregistration',
        element: <CustomerRegistration  />,
        exact: true
    },
    {
        path: '/b2bregistration',
        element: <B2BRegistration  />,
        exact: true
    },
    {
        path: '/driverregistration',
        element: <DriverRegistration  />,
        exact: true
    },
];

export const corporate =[

    {
        path: '/termsconditions',
        element: <TermsConditions />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/privacypolicy',
        element: <PrivacyPolicy />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/',
        element: <Home />,
        exact: true,
        errorElement: <ErrorPage />,
    },
    {
        path: '/booking',
        element: <BookingForm />,
        exact: true
    },
    {
        path: '/signin',
        element: <Login />,
        exact: true
    },
    {
        path: '/forgotpassword',
        element: <ForgotPassword />,
        exact: true
    },
    {
        path: '/resetpassword/:token',
        element: <ResetPassword />,
        exact: true
    },
    {
        path: '/dashboard',
        element: <PrivateRoute> <Dashboard head="MY BOOKINGS" /> </PrivateRoute>,
        exact: true
    },
    {
        path: '/summary',
        element: <Summary head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/edittrip/:id',
        element: <PrivateRoute> <EditTrip head="EDIT TRIP" /> </PrivateRoute>,
        exact: true
    },
    {
        path: '/addrole',
        element: <PrivateRoute><AddRole head="ROLE MANAGEMENT"/></PrivateRoute>,
        exact: true
    },
    {
        path: '/profile',
        element: <PrivateRoute><Profile head="PROFILE"/></PrivateRoute>,
        exact: true
    },
    {
        path: '/user',
        element: <PrivateRoute><User head="USER MANAGEMENT" /></PrivateRoute>,
        exact: true
    },
    {
        path: '/role',
        element: <PrivateRoute><Role head="ROLE MANAGEMENT" /></PrivateRoute>,
        exact: true
    },
    {
        path: '/passengerdetails',
        element: <PassengerDetails head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/newbooking',
        element: <Booking head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/payment',
        element: <Payment head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/done',
        element: <Done head="ADD BOOKING"/>,
        exact: true
    },
    {
        path: '/home',
        element: <Home />,
        exact: true
    },
    {
        path: '/customerregistration',
        element: <CustomerRegistration  />,
        exact: true
    },
    {
        path: '/b2bregistration',
        element: <B2BRegistration  />,
        exact: true
    },
    {
        path: '/driverregistration',
        element: <DriverRegistration  />,
        exact: true
    },
]; 
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './style.scss'
import Person from '@mui/icons-material/Person';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import footerlogo from '../assets/images/ugks_logo.png'
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider'
import { useLocation, useNavigate } from 'react-router-dom'
import { SET_PREVIOUS_ROUTE } from '../Redux/constants/userConstants'
import { Stack } from '@mui/system';
import { Avatar, Grid } from '@mui/material'
import RegisterMenu from './RegisterMenu';
import Link from '@mui/material/Link';
import AboutUs from '../screens/AboutUs';
import facebook from '../assets/images/facebook.png'
import advicer from '../assets/images/advicer.png'

const Footer = () => {

  // const { user } = useSelector(state => state.auth)
  // const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const AboutUs = () => {
    setAnchorElNav(null);
    navigate('/aboutus')
  };
  const TermsConditions = () => {
    setAnchorElNav(null);
    navigate('/termsconditions')
  };
  const PrivacyPolicy = () => {
    setAnchorElNav(null);
    navigate('/privacypolicy')
  };
  const CustomerRegister = () => {
    setAnchorElNav(null);
    navigate('/customerregistration')
  };

  const CompanyRegister = () => {
    setAnchorElNav(null);
    navigate('/b2bregistration')
  };


  return (
    <footer id="footer">
      <Grid className='padd-r-l'>
                <div class="circle-one"></div>
                <div class="circle-two"></div>

        <Grid container spacing={2}>
          <Grid item md={2} xs={12}>

            <div className="logo">
              <img src={footerlogo} alt="footer-logo" />
            </div>

          </Grid>
          <Grid item md={4} xs={12}>

            <h3 className='column_title'>About</h3>
            <p>Singapore has become a tourist favourite, attracting people from all over the world.
            We providing luxury and comfort transport to our client to give them the best stay in Singapore. 
              <span className='readmore_link' onClick={AboutUs}>Read More</span>
              <div className='social-m'>
              <a className='facebook-i' href="https://www.facebook.com/limousine.maxicab.singapore/">
              {/* <svg width="9" height="16" viewBox="0 0 15 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 10V7.02344C10 5.67969 10.2969 5 12.3828 5H15V0H10.6328C5.28125 0 3.51562 2.45313 3.51562 6.66406V10H0V15H3.51562V30H10V15H14.4062L15 10H10Z" fill="white"/>
              </svg> */}
              <img src={facebook} alt="facebook" />
              </a>
              <a href="https://www.tripadvisor.com.sg/Attraction_Review-g294265-d17449220-Reviews-JM_TRAVEL_LIMOUSINE-Singapore.html">
              <img src={advicer} alt="trip_advicer" />
              </a>
              </div>
            </p>

          </Grid>
          <Grid item md={2} xs={12}>

            <h3 className='column_title'>Policies</h3>
            <p className='link' onClick={TermsConditions} >Terms and Conditions</p>
            <p className='link' onClick={PrivacyPolicy} >Privacy Policy</p>


          </Grid>
          <Grid item md={2} xs={12}>

            <h3 className='column_title'>Contact Us</h3>
            <p>+65 8833 2234</p>
            <a href='mailto:jm.limo889@gmail.com'>jm.limo889@gmail.com</a>


          </Grid>
          <Grid item md={2} xs={12}>

            <h3 className='column_title'>Registration</h3>
            <p className='link' onClick={CustomerRegister}>Customer Registration</p>
            <p className='link' onClick={CompanyRegister}>Company Registration</p>


          </Grid>
        </Grid>

      </Grid>
      {/* <div className="copy-right">
        <p>Copyright JM Limousine 2024.All rights reserved.</p>
      </div> */}
    </footer>
  )

}

export default Footer


import React from 'react'
import Banner from '../../components/Banner'
import HomeHeader from '../../components/HomeHeader';
import HomeTopBar from '../../components/HomeTopBar';
import Layout from '../Layout';
import ServicesContent from './ServicesContent';

const Services = () => {

    return (
        <Layout head={"Services"}>
            <ServicesContent/>   
        </Layout>
    )
}

export default Services

import React, { useState } from 'react'
import './style.scss'
import {useLocation} from 'react-router-dom';
import InputField from '../../components/InputField'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InputIcon from '../../components/InputIcon';
import SearchIcon from '@mui/icons-material/Search';
import { Button,Grid,Box,Stack } from '@mui/material'
import Brightness7Icon from '@mui/icons-material/Brightness7';
import AddRole from './AddRole';
import { useNavigate } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import EditImg from '../../assets/images/IconsNew/editicon.png'
import DeleteImg from '../../assets/images/IconsNew/deleteicon.png'
import EditRole from './EditRole';
import DeleteRole from './DeleteRole';

function createData( id,user_name, role, date) {
    return {  id,user_name, role, date };
  }
  
  const rows = [
    createData(1, 'Sanath', 'Booker', "22/05/2022 10:00 am"),
    createData( 2,'Brandon', 'Booker', "15/05/2022 12:00 am"),
    createData( 3,'Zane', 'Accountant', "15/05/2022 12:00 am"),
   
  ];
const RoleList = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(1);
  };
  const EditSelectedRole = (role) => {
		setSelected(role);
		setEditModalShow(true);
	}
	const [editModalShow, setEditModalShow] = useState(false);
	const [selected, setSelected] = useState(null)
	const DeleteSelectedRole = (role) => {
		setSelected(role);
		setDeleteModalShow(true);
	}
	const [deleteModalShow, setDeleteModalShow] = useState(false);


	const columns = [
		{ field: 'date', headerName: 'Date Added', width: 425 },
		{ field: 'role', headerName: 'Role', width: 400 },
		
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			width: 400,
			cellClassName: 'actions',
			renderCell: (params) => {
				return (
					<>
						
						<Button
							variant="text"
							color="primary"
							type="submit"
              onClick={() => EditSelectedRole(params?.row)}
						>
							<img src={EditImg} style={{ width: 30, height: 30 }}/>
						</Button>
						<Button
							variant="text"
							color="primary"
							type="submit"
              onClick={() => DeleteSelectedRole(params?.row)}
						>
							<img src={DeleteImg} style={{ width: 30, height: 30 }}/>
						</Button>
					</>
				);
			},
		},
	];



  const { register, handleSubmit, control, formState:{ errors } } = useForm();

    return (
        <div>
          <Stack direction={"row"} justifyContent="space-between" alignItems={"center"}>
          <h1 class='underline'><span style={{ textDecorationLine:'underline', textDecorationColor:'#47C9D9' }}>Role</span> Details</h1>
            <Grid xs={12} md={6} style={{ display: 'flex',flexDirection:'row' , justifyContent:'flex-end' ,padding:'50px'}}>
            <Button variant="contained" endIcon={<Brightness7Icon  />} 
            style={{color:'white',backgroundColor:'#47C9D9',borderRadius:15,height:40,width:182,fontWeight:'bold'}} className="tabStyle" onClick={() => navigate("/addrole")} >ADD ROLE</Button> 
            </Grid>
          </Stack> 
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }} >
					<div style={{ paddingTop: 5, paddingBottom: 10, marginLeft:'-1%' }}>
          <InputIcon placeholder="Search Role..." fieldName={"search"} control={control} Icon={<SearchIcon/>} variant="outlined" width='43ch'  backgroundColor="none"/>
					</div>
					<div style={{ paddingTop: 5, paddingBottom: 10 }}>
						
					</div>
				</div>
       
          <TableContainer component={Paper} style={{ marginTop: 20 }} >
            <div style={{ height: 450, width: "100%", border: 0 }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{
                  NoRowsOverlay: () => (
                    <Stack height="100%" alignItems="center" justifyContent="center">
                    No records found
                    </Stack>
                  )
                  }}
              />
            </div>
            {/* <Table sx={{ minWidth: 650 }} aria-label="simple table" >
              <TableHead >
                <TableRow sx={{ '&:last-child td, &:last-child th': { boxShadow:'none'} }}>
                  <TableCell align="center">Date Added</TableCell>
                  <TableCell align="center">Role</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((trip) => (
                  <TableRow
                    key={trip.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 ,boxShadow:'none'} }}
                  >
                    <TableCell align="center">{trip.date}</TableCell>
                    <TableCell align="center">{trip.role}</TableCell>
                    <TableCell align="center"><EditIcon cursor="pointer"/>&nbsp;<DeleteIcon  cursor="pointer"/></TableCell>
                  </TableRow>
                ))}
              </TableBody>  
            </Table> */}
            {editModalShow && <EditRole openEditModal={editModalShow} closeEditModal={() => setEditModalShow(false)} roles={selected} />}
					  {deleteModalShow && <DeleteRole openDeleteModal={deleteModalShow} closeDeleteModal={() => setDeleteModalShow(false)} roles={selected} />}
          </TableContainer>
        </div>
    )
}

export default RoleList

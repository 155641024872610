import React, { Fragment, useEffect } from 'react'
import WorkFlow from '../../components/WorkFlow'
import Layout from '../Layout'
import Banner from '../../components/Banner'
import './booking.scss'
import { Card, Typography } from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import Person from '@mui/icons-material/Person';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { height } from '@mui/system';
import LocationImg from '../../assets/images/Icons/placeholder.png'
import TimeImg from '../../assets/images/Icons/timetable.png'
import DistanceImg from '../../assets/images/Icons/measure-distance.png'
import { useLocation } from 'react-router-dom';
import TopBar from '../../components/TopBar'
import BookingDetails from './BookingDetails'
import { useDispatch } from 'react-redux'

const Booking = ({ head }) => {


    return (
        <Layout head={head}>
            <WorkFlow activeStep={0} />
            <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingBottom: '1%', position: 'relative' }}>
                <h1 class='underline srh-b-h'>Booking Details</h1>
            </Grid>
            <BookingDetails />
        </Layout>
    )
}

export default Booking

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Grid, Typography, FormGroup, Snackbar, Alert, Container } from '@mui/material'
import InputField from '../../components/InputField'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { RESET_ERROR } from '../../Redux/constants/topupConstants'
import aboutus_img1 from '../../assets/images/aboutus_img1.jpg'
import aboutus_img2 from '../../assets/images/aboutus_img2.jpg'
import aboutus_img3 from '../../assets/images/aboutus_img3.jpg'
import safety_first from '../../assets/images/safety-first.png'
import reasonable_rates from '../../assets/images/resonable-rate.png'
import largest_fleet from '../../assets/images/largest-fleet.png'
import { toast } from 'react-toastify'
import * as yup from "yup";
import { userRegister } from '../../Redux/actions/userActions';
import PhoneInputs from '../../components/PhoneInputs';
import BorderBox from '../../components/BorderBox';
import { rest } from 'lodash';

const AboutUsContent = () => {


    return (

        <div>
            <div className="about_sec1">

                <Grid container spacing={2} className="container_topspace">
                    {/* <p>Content will be updates soon</p> */}
                    <Grid item md={6} xs={12}>

                        <div className="leftside_textholder">

                            <h3 className="section_heading">Singapore Limousine Maxicab Service - Airport Transfer / Hourly Booking Corporate event.</h3>

                            <div className='abt-c'>
                            <p>Singapore has become a tourist favourite, attracting people from all over the world.
                            We providing luxury and comfort transport to our client to give them the best stay in Singapore.</p>
                            <p>We want to make travelling around Singapore easy and comfortable. Whether you’re looking for airport pickup 
                                or drop-off, private tours, limousine service for corporate events or hourly charters, our fleet consists 
                                of luxurious 4-13 seaters of the highest-quality to ensure our passengers are safe and secure at all times.</p>
                            <p>Our hiring process is stringent so we can filter the best drivers. They are well aware of all the routes, 
                                roads and streets of Singapore. We only hire after conducting thorough background checks.</p>
                            <p>Our clients are our top priority and we want them to know that they are in good hands.
                            Please go through our website to explore the different packages we offer. Give us a call if you require 
                            more information. We’re available 24hours to take you wherever you have to go.</p>
                            </div>

                        </div>

                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div className="rightside_imgholder">

                            <img src={aboutus_img1} />

                        </div>

                    </Grid>

                </Grid>

            </div>

            <div className="about_sec2">

                {/* <Grid container spacing={2} className="container_topspace">

                    <Grid item md={6} xs={12}>
                        <div className="leftside_imgholder">

                            <img src={aboutus_img2} />

                        </div>

                    </Grid>

                    <Grid item md={6} xs={12}>

                        <div className="rightside_textholder">

                            <h3 className="section_heading">Our Fleet</h3>

                            <p>The largest fleet of late model executive and luxury class vehicles in Singapore. Every JMLimousine.com vehicle is a late-model vehicle equipped with the newest in executive convenience appointments, from refined styling to sophisticated computer systems. All vehicles are tracked with continuous location updates for the customer.</p>
                            <ul className='service_point'>
                                <li> Professionally Trained Chauffeurs</li>
                                <li>24/7 Full-Service Transportation</li>
                                <li>Global network of Transportation Partners</li>
                            </ul>
                        </div>

                    </Grid>

                </Grid> */}

            </div>
            <div className="about_sec3">

                {/* <Grid container spacing={2} className="container_topspace">



                    <Grid item md={6} xs={12}>

                        <div className="rightside_textholder">

                            <h3 className="section_heading">Quality Control</h3>

                            <p>A stringent quality control program to monitor and enforce compliance and uniformity. JMLimousine drivers are seasoned and dedicated professionals. Every chauffeur initially receives formal training prior to acceptance as a Franchisee or as an employee and then attends regularly scheduled refresher classes. Primary focus is on customer service and communication skills, passenger safety and defensive driving.</p>

                        </div>

                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div className="leftside_imgholder">

                            <img src={aboutus_img3} />

                        </div>

                    </Grid>

                </Grid> */}

            </div>
            <div className="about_sec4 ">
                {/* <h3 className="section_heading">Benefits of Hiring JM Limo</h3> */}

                {/* <Grid container spacing={2} className="">



                    <Grid item md={4} xs={12}>

                        <div className="box_content">
                            <div className='icon_img'><img src={safety_first} /></div>

                            <h3 className="box_heading">Safety First</h3>

                            <p>Experienced staff and professionally trained chauffeurs.</p>

                        </div>

                    </Grid>
                    <Grid item md={4} xs={12}>

                        <div className="box_content">
                        <div className='icon_img'><img src={reasonable_rates} /></div>
                            <h3 className="box_heading">Resonable Rates</h3>

                            <p>We can offer you the right vehicle at the right price to fit your budget.</p>

                        </div>

                    </Grid>
                    <Grid item md={4} xs={12}>

                        <div className="box_content">
                        <div className='icon_img'><img src={largest_fleet} /></div>
                            <h3 className="box_heading">Largest Fleet</h3>

                            <p>We offer an extensive fleet of vehicles including sedans, limousines and coach buses.</p>

                        </div>

                    </Grid>


                </Grid> */}

            </div>

        </div>

    )
}

export default AboutUsContent

import React, { useState, useRef, useEffect } from 'react'
import './booking.scss'
import { Button, Grid, Typography, FormGroup, ToggleButtonGroup, ToggleButton, Divider } from '@mui/material'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles';
import CountInput from '../../components/CountInput';
import SelectPassenger from '../../components/SelectPassenger';
import PlacesInput from '../../components/PlacesInput';
import DateInput from '../../components/DateInput';
import moment from 'moment';
import { toast } from 'react-toastify';
import DateTimeInput from '../../components/DateTimeInput';
import AutoComplete from '../../components/AutoComplete';
import GooglePlaceInput from '../../components/GooglePlaceInput';
import { set } from 'lodash';
import customAxios from '../../CustomAxios';

const PointToPoint = () => {

	const location = useLocation();
	const { bookingData } = useSelector(state => state.booking)
	const [open, setOpen] = useState(false)
	const dispatch = useDispatch();
	const [alignment, setAlignment] = React.useState('oneway');
	const [checktoday,setcheckday]=useState(bookingData ? moment(bookingData?.pickupdate).isSame(moment(), 'day') : true)
	const navigate = useNavigate();
	const [returnMinDate, setReturnMinDate] = useState(moment().add(0, 'days').toDate())
	const [suggList, setSuggList] = useState([])
    const { user } = useSelector(state => state.auth)

	useEffect(() => {
        searchAddress()
    }, [])

    const searchAddress = async () => {
        await customAxios.get(`search/address/${user?.id}`)
        .then(async response => {

            let sugList = [];
            response?.data?.data?.map((item, index) => {
                let place = JSON.parse(item?.geometry)
                sugList.push({
                    ...place,
                    description: place?.address
                })
            })
            setSuggList(sugList)

        })
        .catch(async error => {

        });

    }

	useEffect(() => {
		if (bookingData) {
			setAlignment(bookingData?.bookingType)
		}
	}, [bookingData])



	const StyledToggle = styled((props) => <ToggleButton disableRipple {...props} />)(
		({ theme }) => ({
			textTransform: 'none',
			fontFamily: 'nunito',
			borderRadius: '0px',
			height: '30px',
			border: '0px',
			fontSize: theme.typography.pxToRem(12),
			marginRight: theme.spacing(0),
			color: '#333333',
			backgroundColor: '#F6F6F6',
			'&.Mui-selected': {
				color: '#fff',
				backgroundColor: '#333333'
			},
			'&.Mui-selected:hover': {
				color: '#fff',
				backgroundColor: '#47C9D9'
			},
		}),
	);


	const handleChange = (event, newAlignment) => {
		if (newAlignment) {
			setAlignment(newAlignment);
		}
	};


	const schema = yup.object({
		location_from: yup.mixed().required("Pick up location is required"),
		location_to: yup.mixed().required("Drop off location is required"),
		pickupdate: yup.string().required("Pickup date & time required").typeError("Pickup date & time required"),
		returndate: yup.string().typeError("Return date & time required").when('bookingType', (bookingType, schema) => {
			return alignment === "return" ? schema.required("Return date & time required") : schema.min(0);
		}),
	}).required();

	const { register, handleSubmit, setValue, reset, control, formState: { errors }, getValues, setError } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			type: 'point',
			location_from: bookingData?.location_from,
			location_to: bookingData?.location_to,
			pickupdate: bookingData?.pickupdate ? new Date(bookingData?.pickupdate) : null,
			passenger: bookingData?.passenger ? bookingData?.passenger : 1,
			adults: bookingData?.adults ? bookingData?.adults : 1,
			baby: bookingData?.baby ? bookingData?.baby : 0,
			childrens: bookingData?.childrens ? bookingData?.childrens : 0,
			minors: bookingData?.minors ? bookingData?.minors : 0,
			returndate: bookingData?.returndate ? new Date(bookingData?.returndate) : '',
			bookingType: alignment
		}
	});





	

	const onSubmit = (data) => {
		if(moment(data.pickupdate) <= moment()){
			toast.info("Booking date time should be greater than current date time")
			return false;
		}
		let extraseats = parseInt(data?.baby) + parseInt(data?.minors) + parseInt(data?.childrens)
		data.extraseats = extraseats
		if (!data?.adults) {
			data.adults = data?.passenger;
			
		}

		if (alignment === "return") {
			
			if (moment(data?.pickupdate) > moment(data?.returndate)) {
				toast.error("Return date should be greater than pickup date")
			}
			else {
				data.bookingType = alignment;

				localStorage.setItem("booking", JSON.stringify(data))
				if (location.pathname.toLowerCase() === "/booking") {

					window.open('/summary', '_blank')
				}
				else {
					navigate('/summary');
				}
			}
		}
		else {
			data.bookingType = alignment;
		// alert(JSON.stringify(data));
			localStorage.setItem("booking", JSON.stringify(data))
			if (location.pathname.toLowerCase() === "/booking") {

				window.open('/summary', '_blank')
			}
			else {
				navigate('/summary');
			}
		}


		//let userId = user.id
		//dispatch(addPointBooking())
	};


	const getPasengers = () => {
		setOpen(false)
		let count = getValues();


		setValue("passenger", count.baby + count.adults + count.minors + count.childrens)

	}
	const resetPasengers = () => {
		setOpen(false)
		setValue("adults", 1)
		setValue("minors", 0)
		setValue("childrens", 0)
		setValue("baby", 0)
		setValue("passenger", 1)

	}

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const setFrom = (value) => { 
        setValue("location_from", value)
		setError('location_from', '')
    }

    const setTo = (value) => { 
        setValue("location_to", value)
		setError('location_to', '')
    }

	return (

		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container>
				<Grid
					item
					xs={12}
					md={12}
					sx={{}}
					
				>

					<ToggleButtonGroup
						color="primary"
						value={alignment}
						exclusive
						onChange={handleChange}
						aria-label="Platform"
						fieldName='bookingType'
						className='toggleRadio'
					>
						<StyledToggle
							value="oneway"
							className='toggleText'
						>
							One Way
						</StyledToggle>
						<StyledToggle
							value="return"
							className='toggleText'
						>Return</StyledToggle>
					</ToggleButtonGroup>
				</Grid>
				<Grid item xs={12} md={alignment === "oneway" ? 6 : 6} sx={{  }}   >
					<GooglePlaceInput
                        control={control} 
                        fieldName={"location_from"} 
                        label={'Pick Up Location'} 
						fieldLabel="Pick Up Location"
						setLocation={setFrom} 
                        error={errors?.location_from?.message}
						suggList={suggList}
						style={{backgroundColor: '#fff', border: '1px solid #47C9D9', borderRadius: 4, width: '100%', height: 45, justifyContent: 'center'}}
                    />
				</Grid>
				<Grid item xs={12} md={alignment === "oneway" ? 6 : 6} sx={{ }} >
					<GooglePlaceInput
                        control={control} 
                        fieldName={"location_to"} 
                        label={'Drop Off Location'}
						fieldLabel={'Drop Off Location'} 
                        setLocation={setTo} 
                        error={errors?.location_to?.message}
						suggList={suggList}
                    />
				</Grid>
				<Grid item xs={12} md={alignment === "oneway" ? 3 : 3} sx={{  }} >
					<DateInput
						todaycheck={checktoday}
						fieldLabel="Pick Up Date & Time"
						fieldName="pickupdate"
						//marginRight={20}
						control={control}
						mode={'hour'}
						error={errors.pickupdate}
						fieldType="datetime"
						// minDate={new Date(Date.now() + ( 3600 * 1000 * 24)).toISOString().slice(0, 16)}
						minDate={new Date().getTime()}
						minTime={null}
						changeDate={(value) => {
							setReturnMinDate(moment(value).add(1, 'hour').toDate())
							//setValue("pickupdate", value)
						}}
					/>
				</Grid>
				{alignment === "return" && <Grid item xs={12} md={3} sx={{ }} >
					<DateInput
						fieldLabel="Return Date & Time"
						fieldName="returndate"
						control={control}
						error={errors.returndate}
						minDate={returnMinDate}
						
						fieldType="datetime"
						fontSize="14px"
						width={"90%"}
						changeDate={(value) => setValue("returndate", value)}
					/>
				</Grid>}
				<Grid item xs={12} md={alignment === "oneway" ? 3 : 3} sx={{ flexGrow: 1 }}>
					<div className='selectpassenger'
					>
						<SelectPassenger
							fieldLabel="Passenger Details"
							control={control}
							fieldName="passenger"
							fontSize="14px"
							width={"100%"}
							open={open}
							onClose={handleClose}
							onOpen={handleOpen}
							values={getValues().passenger}
						>
							<CountInput
								fieldLabel="Adults"
								fieldName="adults"
								error={errors.adults}
								width='30ch'
								fontSize="12px"
								onChange={(value) => setValue("adults", value)}
								minValue={1}
								values={getValues().adults}
							/>
							<Divider alignment='vertical' variant='middle' />
							<CountInput
								fieldLabel="Baby Seats (0 to 12 Months)"
								fieldName="baby"
								error={errors.baby}
								width='30ch'
								fontSize="12px"
								onChange={(value) => setValue("baby", value)}
								minValue={0}
								values={getValues("baby")}
							/>
							<Divider alignment='vertical' variant='middle' />
							<CountInput
								fieldLabel="Child Seats (1 to 2 Yrs)"
								fieldName="childrens"
								error={errors.childrens}
								width='30ch'
								fontSize="12px"
								onChange={(value) => setValue("childrens", value)}
								minValue={0}
								values={getValues("childrens")}
							/>
							<Divider alignment='vertical' variant='middle' />
							<CountInput
								fieldLabel="Booster Seats (3 to 4 Yrs)"
								fieldName="minors"
								error={errors.minors}
								width='30ch'
								fontSize="12px"
								onChange={(value) => setValue("minors", value)}
								minValue={0}
								values={getValues("minors")}
								borderBottom={{ xs: 0, md: "2px solid #F6F6F6 " }}
							/>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center'
								}}
							>
								<Button
									type="button"
									variant="filled"
									onClick={resetPasengers}
									style={{ backgroundColor: '#FFFFFF', borderRadius: 4, height: 'auto', width: 'auto', border: '1px solid #333333' }}>
									<Typography className='resetButton' >RESET</Typography>
								</Button>
								<span style={{ padding: 2 }} />
								<Button
									type="button"
									variant="filled"
									onClick={getPasengers}
									style={{ backgroundColor: '#333333', borderRadius: 4, height: 'auto', width: 'auto' }}>
									<Typography className='buttonText' >APPLY</Typography>
								</Button>
							</div>
						</SelectPassenger>
					</div>
				</Grid>
			</Grid>
			<div className='searchbtn-holder' style={{  }}>
				<Button type="submit" className="btn-search" variant="filled" style={{ }}>
					{/* <Typography className='buttonText' >NEXT</Typography></Button> */}
					<Typography className='buttonText'>Search</Typography></Button>
			</div>
		</form>

	)
}

export default PointToPoint

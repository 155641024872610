import React from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Divider, Typography } from '@mui/material'
import './style.scss'
import Grid from '@mui/material/Unstable_Grid2';


const MainStop = ({ address, count, color, point }) => {
  return (
    <div className='location_innerbox'>
      {/* <Grid container spacing={2} >
          <Grid item xs={4} md={4} sx={4}> */}
              <span className='img_icon sum-icon' style={{ position: 'relative' }}>
                <LocationOnIcon style={{ width: 20, height: 20, color: '#333' }} />
                <div style={{ fontSize: 8, width: 10, height: 10, borderRadius: 5, backgroundColor: color, color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', top: 0, right: -3, position: 'absolute' }}>{count}</div>
              </span>
          {/* </Grid>
          <Grid item xs={8} md={8} sx={8}> */}
            <div className='sum-address'>
              <span className='loctext pick-drop' >{point}</span>
              <span className='loctext loc-address' >{address}</span>
            </div>
          {/* </Grid>
      </Grid> */}
    </div>
  )
}

export default MainStop